import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';

@Directive({
  selector: '[sbActionConfirm]',
  standalone: true,
})
export class ActionConfirmDirective implements OnDestroy {
  @Input()
  public confirmText?: string;
  @Input()
  public acceptText?: string;
  @Input()
  public rejectText?: string;
  @Input()
  public disabled: boolean;
  @Output()
  public confirmed: EventEmitter<Event> = new EventEmitter<Event>();
  @Output()
  public cancelled: EventEmitter<Event> = new EventEmitter<Event>();

  public constructor(private translate: TranslateService, private confirmationService: ConfirmationService) {}

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    if (this.disabled) {
      return;
    }
    this.confirm(event);
  }

  public ngOnDestroy(): void {
    this.confirmationService.close();
  }

  private confirm(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: this.confirmText || this.translate.instant('Are you sure?'),
      acceptLabel: this.acceptText || this.translate.instant('Yes'),
      rejectLabel: this.rejectText || this.translate.instant('No'),
      acceptButtonStyleClass: 'font-semibold',
      rejectButtonStyleClass: 'font-semibold',
      accept: () => {
        this.confirmed.emit(event);
      },
      reject: () => {
        this.cancelled.emit(event);
      },
    });
  }
}
